import { render, staticRenderFns } from "./IndexFooter.vue?vue&type=template&id=071a4742&"
var script = {}
import style0 from "./IndexFooter.vue?vue&type=style&index=0&id=071a4742&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexLogos: require('/app/components/index/IndexLogos.vue').default,AppFooterSocials: require('/app/components/app/footer/AppFooterSocials.vue').default})
